import SectionHero from "components/SectionHero/SectionHero";
import SectionSliderNewCategories from "components/SectionSliderNewCategories/SectionSliderNewCategories";
import SectionGridFeaturePlaces from "./SectionGridFeaturePlaces";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import { TaxonomyType } from "data/types";

const imgExperiencias = require('assets/categories/1.png');
const imgAlojamientos = require('assets/categories/2.png');
const imgGastronomia = require('assets/categories/3.png');
const imgFamilia = require('assets/categories/4.png');
const imgPareja = require('assets/categories/5.png');

const CATEGORIAS: TaxonomyType[] = [
  {
    id: "1",
    href: "/alojamientos",
    name: "Alojamientos",
    taxonomy: "category",
    count: 0,
    thumbnail: imgAlojamientos
  },
  {
    id: "2",
    href: "/listing-stay",
    name: "Experiencias",
    taxonomy: "category",
    count: 0,
    thumbnail: imgExperiencias
  },
  {
    id: "2",
    href: "/listing-stay",
    name: "Gastronomía",
    taxonomy: "category",
    count: 0,
    thumbnail: imgGastronomia
  },
  {
    id: "2",
    href: "/listing-stay",
    name: "En familia",
    taxonomy: "category",
    count: 0,
    thumbnail: imgFamilia
  },
  {
    id: "2",
    href: "/listing-stay",
    name: "En pareja",
    taxonomy: "category",
    count: 0,
    thumbnail: imgPareja
  },

];

function PageHome() {
  return (
    <div className="nc-PageHome relative overflow-hidden">
      {/* GLASSMOPHIN */}
      <BgGlassmorphism />

      <div className="container relative space-y-24 mb-24 lg:space-y-28 lg:mb-28">
        {/* SECTION HERO */}
        <div className="container px-1 sm:px-4 mb-24 ">
          <SectionHero className="pt-10 lg:pt-16 lg:pb-16" />
        </div>

        {/* SECTION 1 */}
        <SectionSliderNewCategories
          categories={CATEGORIAS}
          uniqueClassName="PageHome_s1"
        />


        {/* SECTION */}
        <div className="relative py-16">
          <BackgroundSection />
          <SectionGridFeaturePlaces />
        </div>

      </div>
    </div>
  );
}

export default PageHome;
