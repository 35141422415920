import { ArrowRightOnRectangleIcon, LifebuoyIcon } from "@heroicons/react/24/solid";
import { LanguageContext } from "context/LanguageProvider";
import { useForm } from "hooks/useForm";
import { useContext } from "react";
import { useDispatch } from "react-redux";
import { logout } from "store/auth/authSlice";


import { translations as tr } from "utils/TranslationService";


type SolutionsFootType = {
    name: string;
    href: string;
    icon: React.ForwardRefExoticComponent<Omit<React.SVGProps<SVGSVGElement>, "ref"> & {
        title?: string | undefined;
        titleId?: string | undefined;
    } & React.RefAttributes<SVGSVGElement>>
    action?: () => void;
}

const initialState = {

    status: 'not-authenticated',
    email: '',
    password: '',
    uid: null,
    displayName: null,
    photoURL: null,
    errorMessage: '',
}

export const SolutionsFoot = () => {
    const { onResetForm,/* formState*/ } = useForm(initialState);
    const { translate } = useContext(LanguageContext);
    const dispatch = useDispatch();

    const solutionsFoot: SolutionsFootType[] = [
        {
            name: (translate(tr.Help) || translate(tr.TraduccionInvalida)),
            href: "##",
            icon: LifebuoyIcon,
        },

        {
            name: (translate(tr.Logout) || translate(tr.TraduccionInvalida)),
            href: "/login",
            icon: ArrowRightOnRectangleIcon,
            action: async () => {
                try {
                    dispatch(logout('User Logout'));
                    onResetForm(initialState);
                } catch (err) {
                    console.error(err);
                }
            }
        },
    ];
    return solutionsFoot;
}