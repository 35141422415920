import { combineReducers } from 'redux';
import { configureStore } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import AsyncStorage from '@react-native-async-storage/async-storage';

import authSlice from './auth/authSlice'

const appReducer = combineReducers({
    auth: authSlice
});

const persistConfig = {
    key: 'root',
    version: 1,
    storage: AsyncStorage,
};

export const store = configureStore({
    reducer: persistReducer(persistConfig, appReducer),
    middleware: getDefaultMiddleware =>
        getDefaultMiddleware({
            serializableCheck: false,
            immutableCheck: false,
        }),
});

export const persistor = persistStore(store);


export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch;