import ReactDOM from "react-dom/client";
import "rc-slider/assets/index.css";
import "./fonts/line-awesome-1.3.0/css/line-awesome.css";
import "./styles/index.scss";
import "./index.css";

import App from "./App";
import reportWebVitals from "./reportWebVitals";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(<App />);
reportWebVitals();
