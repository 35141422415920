import { Provider } from "react-redux";
import { persistor, store } from "store";
import { FloatWhatsappButon } from "components/FloatWhatsappButton/FloatWhatsappButton";
import { PersistGate } from "redux-persist/integration/react";
import MyRouter from "routers/index";

function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={<h4>Cargando</h4>} persistor={persistor}>
        <div className="bg-white text-base dark:bg-neutral-900 text-neutral-900 dark:text-neutral-200">
          <MyRouter />
          <FloatWhatsappButon />
        </div>
      </PersistGate>
    </Provider>
  );
}

export default App;
