import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Page } from "./types";

import { Provider } from "react-redux";
import ScrollToTop from "./ScrollToTop";
import Footer from "shared/Footer/Footer";
import PageHome from "containers/PageHome/PageHome";
import Page404 from "containers/Page404/Page404";
import ListingStayPage from "containers/ListingStayPage/ListingStayPage";
import ListingStayMapPage from "containers/ListingStayPage/ListingStayMapPage";
import ListingExperiencesPage from "containers/ListingExperiencesPage/ListingExperiencesPage";
import ListingExperiencesMapPage from "containers/ListingExperiencesPage/ListingExperiencesMapPage";
import CheckOutPage from "containers/CheckOutPage/CheckOutPage";
import PayPage from "containers/PayPage/PayPage";
import AuthorPage from "containers/AuthorPage/AuthorPage";
import AccountPage from "containers/AccountPage/AccountPage";
import AccountPass from "containers/AccountPage/AccountPass";
import AccountSavelists from "containers/AccountPage/AccountSavelists";
import AccountBilling from "containers/AccountPage/AccountBilling";
import PageContact from "containers/PageContact/PageContact";
import PageSignUp from "containers/PageSignUp/PageSignUp";
import PageLogin from "containers/PageLogin/PageLogin";
import BlogPage from "containers/BlogPage/BlogPage";
import BlogSingle from "containers/BlogPage/BlogSingle";
import SiteHeader from "containers/SiteHeader";
import FooterNav from "components/FooterNav";
import useWindowSize from "hooks/useWindowResize";
import ListingStayDetailPage from "containers/ListingDetailPage/listing-stay-detail/ListingStayDetailPage";
import ListingExperiencesDetailPage from "containers/ListingDetailPage/listing-experiences-detail/ListingExperiencesDetailPage";
import { LanguageProvider } from "context/LanguageProvider";
import { ForgotPasswordPage } from "containers/ForgotPassword/ForgotPasswordPage";
import { PublicRoute } from "./PublicRoute";
import { DropdownProvider } from "context/DropdownContext";
import { store } from "store";

export const authPages: Page[] = [
  { path: "/login", Component: <PageLogin /> },
  { path: "/signup", Component: <PageSignUp /> },
  { path: "/forgot-pass", Component: <ForgotPasswordPage /> },
  { path: "/", exact: true, Component: <PageHome /> },
]

export const publicPages: Page[] = [
  /*--------------------Home Pages-------------------------*/
  { path: "/", exact: true, Component: <PageHome /> },
  { path: "/#", exact: true, Component: <PageHome /> },
  { path: "/home-1-header-2", exact: true, Component: <PageHome /> },
  { path: "/contacto", Component: <PageContact /> },
  { path: "/blog", Component: <BlogPage /> },
  { path: "/blog-single", Component: <BlogSingle /> },

  /*--------------------Account Property Pages-------------------------*/
  { path: "/author", Component: <AuthorPage /> },
  { path: "/account", Component: <AccountPage /> },
  { path: "/account-password", Component: <AccountPass /> },
  { path: "/account-savelists", Component: <AccountSavelists /> },
  { path: "/account-billing", Component: <AccountBilling /> },

  /*--------------------Listing Pages-------------------------*/
  { path: "/listing-stay", Component: <ListingStayPage /> },
  { path: "/listing-stay-map", Component: <ListingStayMapPage /> },
  { path: "/listing-stay-detail", Component: <ListingStayDetailPage /> },
  { path: "/listing-experiences", Component: <ListingExperiencesPage /> },
  { path: "/listing-experiences-map", Component: <ListingExperiencesMapPage /> },
  { path: "/listing-experiences-detail", Component: <ListingExperiencesDetailPage /> },

  /*--------------------Payment Pages-------------------------*/
  { path: "/checkout", Component: <CheckOutPage /> },
  { path: "/pay-done", Component: <PayPage /> },
  { path: "/*", Component: <Page404 /> },
]

export const MyRoutes = () => {
  let WIN_WIDTH = useWindowSize().width;
  if (typeof window !== "undefined") {
    WIN_WIDTH = WIN_WIDTH || window.innerWidth;
  }

  return (
    <LanguageProvider>
      <DropdownProvider>
        <Provider store={store}>
          <BrowserRouter>
            <ScrollToTop />
            <SiteHeader />
            <Routes>
              <Route path="/*" element={<PublicRoute />} />
            </Routes>
            {WIN_WIDTH < 768 && <FooterNav />}
            <Footer />
          </BrowserRouter>
        </Provider>
      </DropdownProvider>
    </LanguageProvider>
  );
};

export default MyRoutes;
